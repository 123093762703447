// @ts-nocheck
import React, {
  memo,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback
} from 'react';
import { debounce, isEqual } from 'lodash';

// mui
import {
  Autocomplete,
  CircularProgress,
  Grow,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
// types
import { SearchBarAutoCompleteProps } from '../types';

import { SIZE_REDUCTION_ALLOWED, SEARCH_BAR_PAGE } from '../const';

// custom icons
import { SearchIcon, TimePastIcon } from '../../../assets/svgs/Icons';

// styles
import styles from '../styles/SearchBarAutoComplete.styles';
import CrossIcon from '../../../assets/svgs/Icons/CrossIcon';
import RetainFilters from './RetainFilters';

const SearchBarAutoComplete = ({
  id,
  label,
  onInputChange,
  setSearch,
  open,
  onOpen,
  onClose,
  loading,
  options = [],
  value,
  filterOptions,
  onFocus,
  noIcon,
  clearSearchSuggestions,
  disableSearch = false,
  type,
  handleSearch,
  handleClearSearch,
  showRetainFilter,
  onRetainFilterChange,
  disableRetainFilter,
  retainFilter,
  subType = '',
  isMultiLine = false
}: SearchBarAutoCompleteProps) => {
  const autoCompleteRef = useRef<HTMLInputElement>(null);
  const [maxHeight, setMaxHeight] = useState(400); // Initial maxHeight value
  const [sizeReductionPadding, setSizeReductionPadding] = useState(0);

  const currentFocusedOptionToSelect = useMemo(() => {
    return options.find(option => option.title === value) ?? options[0];
  }, [options, value]);

  const [currentFocusedOption, setCurrentFocusedOption] = useState(currentFocusedOptionToSelect);

  const replaceLastWord = useCallback((text: string, word: string) => {
    const words = text.split(' ');
    words[words.length - 1] = word;
    return words.join(' ');
  }, []);

  const handleOnChange = useCallback(
    (event: SyntheticEvent<Element, Event> | null, newValue: string) => {
      if (!event) {
        setSearch?.(newValue);
        return;
      }

      let query = newValue;

      // If the user clicks on a word option, replace the last word in the search text with the clicked word
      if (event?.type === 'click' && currentFocusedOption?.autocomplete_type === 'word') {
        query = replaceLastWord(value, currentFocusedOption?.title);
      }

      setSearch?.(query);
      onInputChange?.(query);
    },
    [currentFocusedOption, value, setSearch, onInputChange]
  );

  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Tab') {
        event.preventDefault();

        const query =
          currentFocusedOption?.autocomplete_type === 'word'
            ? replaceLastWord(value, currentFocusedOption?.title)
            : currentFocusedOption?.title;

        setSearch?.(query);
        onInputChange?.(query);
      }

      if (event.key === 'Enter' && value?.trim()?.length > 0) {
        handleSearch?.(value);
      }
    },
    [currentFocusedOption, value, handleSearch, onInputChange, setSearch]
  );

  const getSearchBarPage = useCallback(() => {
    if (type === 'nav' && subType === '') {
      return SEARCH_BAR_PAGE.RESULTS_PAGE;
    }

    if (!type && !subType) {
      return SEARCH_BAR_PAGE.ARIA_DRAWER;
    }

    if (subType === 'aria') {
      return SEARCH_BAR_PAGE.ARIA_DRAWER_RESULTS;
    }

    return SEARCH_BAR_PAGE.HOMEPAGE;
  }, [type, subType]);

  const getSizeReductionPadding = useCallback(
    (text: string) => {
      const isAllOptionsWords = options.every(option => option.autocomplete_type === 'word');

      if (!isAllOptionsWords) {
        return 0;
      }

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = '13px normal';
      const calculatedWidth = Math.round(context.measureText(text).width);

      const searchingOn = getSearchBarPage();

      // calculating for multiple lines
      const MAX_ALLOWED_SINGLE_LINE_SIZE = SIZE_REDUCTION_ALLOWED[searchingOn].multiLine;
      if (calculatedWidth >= MAX_ALLOWED_SINGLE_LINE_SIZE && isMultiLine) {
        const reductionMultiplier = Math.floor(calculatedWidth / MAX_ALLOWED_SINGLE_LINE_SIZE);
        return Math.round(calculatedWidth - MAX_ALLOWED_SINGLE_LINE_SIZE * reductionMultiplier);
      }

      // calculating for single line
      const MAX_ALLOWED_SIZE_REDUCTION = SIZE_REDUCTION_ALLOWED[searchingOn].singleLine;
      return calculatedWidth > MAX_ALLOWED_SIZE_REDUCTION
        ? MAX_ALLOWED_SIZE_REDUCTION
        : calculatedWidth;
    },
    [options, value]
  );

  useEffect(() => {
    const newSize = getSizeReductionPadding(value);

    setSizeReductionPadding(newSize);
  }, [value, options, getSizeReductionPadding]);

  useEffect(() => {
    const calculateHeightOfPopup = () => {
      if (!autoCompleteRef?.current) return;
      const rect = autoCompleteRef.current.getBoundingClientRect();
      // Calculate the maxHeight based on the distance to the bottom of the screen
      const distanceToBottom = window.innerHeight - rect.bottom;
      const calculatedMaxHeight = Math.min(400, distanceToBottom - 50); // Adjust 50 for bottom margin
      setMaxHeight(calculatedMaxHeight);
    };
    const debouncedCalculateHeight = debounce(calculateHeightOfPopup, 300); // Adjust debounce time as needed
    calculateHeightOfPopup();
    window.addEventListener('resize', debouncedCalculateHeight);
    return () => {
      window.removeEventListener('resize', debouncedCalculateHeight);
    };
  }, [autoCompleteRef?.current]);

  return (
    <Autocomplete
      id={id}
      ref={autoCompleteRef}
      autoFocus
      freeSolo
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      loading={loading}
      options={loading ? [] : options}
      value={value}
      onHighlightChange={(event, option) => {
        event?.preventDefault();
        if (option) {
          setCurrentFocusedOption(option);
        }
      }}
      onKeyDown={event => handleKeyDown(event)}
      filterOptions={filterOptions}
      onInputChange={handleOnChange}
      onFocus={onFocus}
      disabled={disableSearch}
      disablePortal={type === 'nav' && subType === ''}
      getOptionLabel={option => (typeof option === 'string' ? option : option.title)}
      getOptionDisabled={option => option.type === 'placeholder'}
      disableClearable
      autoHighlight
      sx={
        type === 'nav' || type === 'aria'
          ? {
              ...styles.searchNav,
              '& .MuiAutocomplete-popper': {
                backgroundColor: 'red'
              }
            }
          : {
              ...styles.search,
              '& .MuiAutocomplete-popper': {
                backgroundColor: 'red'
              }
            }
      }
      ListboxProps={{ style: { maxHeight: `${maxHeight}px` } }}
      componentsProps={{
        popper: {
          sx: {
            ...(type === 'nav' &&
              subType === '' && {
                zIndex: 2,
                position: 'fixed !important',
                height: 'auto !important',
                minWidth: '500px !important',
                width: '20% !important',
                transform: 'none !important',
                left: `180px !important`,
                top: `60px !important`
              }),
            pl: `${sizeReductionPadding}px`,
            backgroundColor: 'unset !important'
          },
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 11]
              }
            },
            {
              name: 'flip',
              enabled: false
            }
          ]
        }
      }}
      renderInput={params => {
        return (
          <TextField
            ref={params.InputProps.ref}
            required
            multiline={isMultiLine}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder={label}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 13 },
              endAdornment: (
                <Grow in={showRetainFilter || subType === 'aria'} unmountOnExit>
                  <InputAdornment
                    position='end'
                    sx={type !== 'nav' && noIcon ? { display: 'none' } : {}}>
                    <Grow in={value?.length > 0} unmountOnExit>
                      <IconButton
                        sx={styles.closeButton}
                        onClick={() => {
                          handleOnChange(null, '');
                          handleClearSearch?.();
                          onClose();
                          clearSearchSuggestions();
                        }}>
                        <CrossIcon />
                      </IconButton>
                    </Grow>
                    <RetainFilters
                      disabled={disableRetainFilter}
                      onChange={onRetainFilterChange}
                      show={showRetainFilter}
                      value={retainFilter}
                    />
                    {type === 'nav' &&
                      (loading ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : (
                        <IconButton type='submit' disabled={disableSearch} sx={styles.searchButton}>
                          <SearchIcon style={{ fontSize: 12, color: 'gray.50' }} />
                        </IconButton>
                      ))}

                    {type === 'aria' &&
                      (loading ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : (
                        <IconButton
                          type='submit'
                          disabled={disableSearch}
                          sx={styles.searchButtonAria}>
                          <SearchIcon fontSize='inherit' />
                        </IconButton>
                      ))}
                  </InputAdornment>
                </Grow>
              )
            }}
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <Stack
            direction='row'
            spacing={1}
            component='li'
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}>
            {option.type === 'history' && <TimePastIcon sx={styles.greyIcon} />}
            <Typography
              variant='subtitle2'
              sx={option.type === 'history' ? styles.greenText : styles.normalText}
              dangerouslySetInnerHTML={{
                __html: `${
                  option.autocomplete_type === 'word' && value?.split(' ')?.length > 1 ? '...' : ''
                }${option.highlighted_title ?? option.title}`
              }}
            />
            {isEqual(option, currentFocusedOption) && (
              <Stack direction='row' spacing={0.25} sx={styles.pressTabContainer}>
                <Typography sx={styles.pressTabText}>Press</Typography>
                <Typography sx={{ ...styles.pressTabText, fontWeight: 700 }}>Tab</Typography>
              </Stack>
            )}
          </Stack>
        );
      }}
    />
  );
};

SearchBarAutoComplete.defaultProps = {
  onInputChange: () => {},
  setSearch: () => {},
  options: [],
  filterOptions: (options: { label: string; value: string }[]) => options,
  onFocus: () => {},
  noIcon: false,
  disableSearch: false,
  isMultiLine: false
};

export default memo(SearchBarAutoComplete);
