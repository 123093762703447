import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

// Imports of API.
import { getAutoCompleteNew } from '../../../api/modules/SearchAutocomplete';

// types
import { SearchSuggestionsResult, SourceDropdown } from '../types';

import { createSourceModulePayloadMapping } from '../utils';

const useSearchSuggestions = (selectedSources: SourceDropdown[]) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<SearchSuggestionsResult[]>([]);

  const isSuggestionsLoading = useMemo(
    () => isSearchOpen && searchSuggestions.length === 0,
    [isSearchOpen, searchSuggestions]
  );

  const clearSearchSuggestions = useCallback(() => {
    setIsSearchOpen(false);
    setSearchSuggestions([]);
  }, []);

  const handleGetSuggestions = useCallback(
    async (textValue: string) => {
      setSearchSuggestions([]);
      let suggestions: SearchSuggestionsResult[] = [];
      try {
        const convertedSelectedSources: any = createSourceModulePayloadMapping(selectedSources);

        selectedSources.forEach(sourceDropdown => {
          sourceDropdown.module.forEach(module => {
            const { sourceValue, value } = module;

            if (!convertedSelectedSources[sourceValue]) {
              convertedSelectedSources[sourceValue] = [];
            }

            convertedSelectedSources[sourceValue].push(value);
          });
        });

        const payload = {
          source: convertedSelectedSources
        };
        const res = await getAutoCompleteNew(encodeURI(textValue) ?? "''", payload);

        const searchResultResponse = (res.data.body as SearchSuggestionsResult[]) ?? [];
        suggestions = [...suggestions, ...searchResultResponse];
        setSearchSuggestions(suggestions);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [selectedSources]
  );

  useEffect(() => {
    handleGetSuggestions('');
  }, []);

  const handleKeyPress = useMemo(() => debounce(handleGetSuggestions, 200), [handleGetSuggestions]);

  return {
    searchSuggestions,
    isSearchOpen,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions
  };
};

export default useSearchSuggestions;
