import React, { createContext, useReducer } from 'react';

import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import reducers from './reducers';
import { SORT_OPTIONS } from '../../pages/SearchResults/constants';

type Props = {
  children: React.ReactNode;
};

type CollatedSummarySelectedCategory = {
  id: string;
  source: string;
  sections: string;
};

interface ResultsContextInterface {
  loading: boolean;
  selectedDocument: any;
  documentResults: any;
  applicationResults: any;
  ctResults: any;
  viewType?: string;
  toggleViewType: string;
  decryptedSource: any;
  labelComparisonSelection: boolean;
  comparisonApplications: Array<any>;
  isFavorite: boolean;
  isSubscribed: boolean;
  entities: any;
  inProjects: Array<any>;
  decryptedPayload: any;
  hiddenApplications: Array<any>;
  applications: Array<any>;
  isGroupByFilterApplied: boolean;
  triggerRestoreApplications: boolean;
  selectedGroupOption: any;
  selectedSortOption: any;
  filters: any;
  synonymsList: Array<any>;
  showUniqueResults: any;
  exploreResultLength: number;
  explorePageMapper: any;
  searchIds: {
    documentSearchId: string;
    applicationSearchId: string;
    ctSearchId: string;
    ariaSearchId?: string;
  };
  trialLoading: boolean;
  trialsData: any;
  trialError: boolean | any;
  ctBusinessIntelligenceLoading: boolean;
  ctAdverseEventLoading: boolean;
  // TODO: spelling wrong
  ctBussinessIntelligence: any;
  ctAdverseEvent: Array<any>;
  isContentLoading: boolean;
  originalTextEnabled: boolean;
  isAriaOpened?: boolean;
  ariaEntityIds?: Array<any>;
  documentLoader: boolean;
  ariaFilters: any;
  ariaResponseLoading: boolean;
  selectedAriaResponse: any;
  cachedAriaResponse: any;
  ariaResponseAnswerText: string;
  exploreResultsOpen: boolean;
  collatedSummarySelection: boolean;
  collatedSummaryApplications: Array<any>;
  collatedSummarySelectedApplications: Array<any>;
  collatedSummarySelectedCategories: CollatedSummarySelectedCategory[];
  ctStats: any;
  ctFilters: any;
  ctPagination: any;
  ctIsLoading: boolean;
  ctDataGridFilters: any;
  ctSortBy: any;
  ctExport: any;
  top10Summary: Array<any>;
  showTop10Summary: boolean;
  top10SummaryLoading: boolean;
  riaResponseTitle: string;
  resultsIdsInView: Array<string>;
  collatedSummaryDownloadType: string | null;
  labelComparisonSelectionMethod: any;
  isSourceChangedWithFilters: boolean;
  isNewAdvanceSearch: boolean;
  searchSuggestions: Array<any>;
  advanceSearchAlertMsg: boolean;
  isSearchBySearchId: boolean;
  isNewSearchTerm: boolean;
  scrollResultId: any;
  defaultSources: {
    [key: string]: Array<string>;
  };
}

const initialState = {
  loading: false,
  selectedDocument: null,
  documentResults: {},
  applicationResults: {},
  viewType: '',
  toggleViewType: 'grid',
  decryptedSource: {},
  labelComparisonSelection: false,
  comparisonApplications: [],
  isFavorite: false,
  isSubscribed: false,
  entities: {},
  inProjects: [],
  decryptedPayload: {},
  hiddenApplications: [],
  applications: [],
  isGroupByFilterApplied: false,
  triggerRestoreApplications: false,
  selectedGroupOption: null,
  selectedSortOption: cloneDeep(SORT_OPTIONS?.find((opt: any) => opt.selected)),
  filters: {},
  synonymsList: [],
  showUniqueResults: {
    enabled: false
  },
  exploreResultLength: 0,
  explorePageMapper: {
    source: '',
    commonMapper: true
  },
  searchIds: {
    documentSearchId: '',
    applicationSearchId: '',
    ctSearchId: '',
    ariaSearchId: ''
  },
  ctResults: {},
  trialLoading: false,
  trialsData: {},
  trialError: false,
  ctBusinessIntelligenceLoading: false,
  ctAdverseEventLoading: false,
  // TODO: spelling wrong
  ctBussinessIntelligence: {},
  ctAdverseEvent: [],
  isContentLoading: false,
  originalTextEnabled: false,
  isAriaOpened: false,
  ariaEntityIds: [],
  documentLoader: false,
  exploreResultsOpen: false,
  ariaFilters: {},
  ariaResponseLoading: false,
  selectedAriaResponse: {},
  ariaResponseAnswerText: '',
  cachedAriaResponse: {}, // this will store the aria response text generated by gpt for different results and work as cache to avoid subsequent call for same result
  collatedSummarySelection: false,
  collatedSummaryApplications: [],
  collatedSummarySelectedApplications: [],
  collatedSummarySelectedCategories: [
    {
      id: v4(),
      source: '',
      sections: ''
    }
  ],
  ctStats: {},
  ctFilters: {},
  ctPagination: {},
  ctIsLoading: false,
  ctDataGridFilters: {},
  ctSortBy: {},
  ctExport: {},
  riaResponseTitle: 'Top Result',
  top10SummaryLoading: false,
  top10Summary: [],
  showTop10Summary: false,
  resultsIdsInView: [],
  collatedSummaryDownloadType: null,
  labelComparisonSelectionMethod: null,
  isSourceChangedWithFilters: false,
  isNewAdvanceSearch: false,
  searchSuggestions: [],
  advanceSearchAlertMsg: false,
  isSearchBySearchId: false,
  isNewSearchTerm: false,
  scrollResultId: {},
  defaultSources: {}
};

const ResultsStore = createContext<{
  resultsState: ResultsContextInterface;
  resultsDispatch: React.Dispatch<any>;
}>({ resultsState: initialState, resultsDispatch: () => null });

const { Provider } = ResultsStore;

export const ResultsStateProvider = ({ children }: Props) => {
  const [resultsState, resultsDispatch]: any = useReducer(reducers, initialState);
  return <Provider value={{ resultsState, resultsDispatch }}>{children}</Provider>;
};

export default ResultsStore;
